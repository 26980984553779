<template lang="">
    <div>
        <div class="header">
             <span @click="previousPage()"></span>
             <span>拆解分配</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="mains">
            
            <div>
                <span>拆解类型：</span>
              <select id="group" v-model ='disassembleTypeId'  style="width:60%;height: 0.75rem;" placeholder="请选择">
           <option value="">请选择</option>

                    <option v-for="item in disassembleTypeIdList" :key = item.id :value='item.id'>{{item.name}}</option>
                    <!-- <option value="2"vertical</option> -->
                </select>    
            </div>
        
          <div>
                <span>拆解方式：</span>
                <select id="group" v-model ='disassemblyMethodId'  style="width:60%;height: 0.75rem;" placeholder="请选择">
                    <option value="">请选择</option>

                    <option v-for="item in disassemblyMethodIdList" :key = item.id :value='item.id'>{{item.name}}</option>
                    <!-- <option value="2"vertical</option> -->
                </select>    
            </div>
        
             <div>
                <span>拆解人：</span>
                <select id="group" v-model ='disassembleUserId'  style="width:60%;" placeholder="请选择" multiple="multiple">
                    <option value="">请选择</option>

                    <option v-for="item in disassembleUserIdList" :key = item.id :value='item.id'>{{item.realName}}</option>
                    <!-- <option value="2"vertical</option> -->
                </select> 
                <!-- <input type="text" name="" id="" v-model="disassembleUserId">         -->
            </div>
            <div>
                <span>拆解价格：</span>
                <input type="text" name="" id="" v-model="price">        
            </div>
            <div>
                <span>拆解批次号：</span>
                <input type="text" name="" id="" v-model="dismant_batch_number">        
            </div>
            <div>
                <span>备注：</span>
                <input type="text" name="" id="" style="height: 2rem;" v-model="remark">        
            </div>
            <div>
                <span>分配车辆：</span>
                <span  style="height: 1rem;width:60%" >{{cars}}    </span>   
            </div>
          

        </div>
        <div class="btn_box">
            <div @click="addData()">确定</div>
            <div>取消</div>
        </div>
    </div>
</template>
<script>
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {deleteCookie} from '../../utils/env'
export default {
    data() {
        return {
            disassembleTypeId:"",//拆解类型
            disassembleTypeIdList:"",//拆解类型
            disassemblyMethodId:"",//拆解方式
            disassemblyMethodIdList:"",//拆解方式
            disassembleUserId:"",//拆解人
            disassembleUserIdList:"",
            price:"",//拆解价格
            remark:"",//备注
            cars:'',
            dismant_batch_number:"",
        }
    },
    created() {
        if(this.$route.query.vehicleModel != null && this.$route.query.vehicleBrand != null  ) {
            this.cars = this.$route.query.id+'('+this.$route.query.vehicleModel+this.$route.query.vehicleBrand+')'

        }else{
             this.cars = this.$route.query.id
        }
        //拆解分类
        this.$http.post('/index.php/index/Simply',{type:'disassembleType'}).then(res => {
            // //console.log(res)
            this.disassembleTypeIdList = res.data.data
            if(res.data.code == 0){
                this.disassembleTypeIdList = res.data.data

            }else if(res.data.code == '-1'){
                deleteCookie('token')
                this.$router.push('login')
            //   window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
            //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
            //         if(res.data.code == 0){
            //             let domain = this.zhuan(res.data.data)
            //             let url = window.location.origin
                
            //             window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
            //         }
            //     })

                this.$toast.fail(res.data.msg)
            }else{
                this.$toast.fail(res.data.msg)

            }

        })
           //拆解方式
        this.$http.post('/index.php/index/Simply',{type:'disassemblyMethod'}).then(res => {
            // //console.log(res)
              if(res.data.code == 0){
                this.disassemblyMethodIdList = res.data.data

            }else if(res.data.code == '-1'){
                this.$router.push('login')
                deleteCookie('token')
            //   window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
            //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
            //         if(res.data.code == 0){
            //             let domain = this.zhuan(res.data.data)
            //             let url = window.location.origin
                
            //             window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
            //         }
            //     })

                this.$toast.fail(res.data.msg)
            }else{
                this.$toast.fail(res.data.msg)

            }

        })
             //拆解人
        this.$http.post('/index.php/index/user/userList',{type:'bulkBreaker'}).then(res => {
            //console.log(res)
               if(res.data.code == 0){
                this.disassembleUserIdList = res.data.data

            }else if(res.data.code == '-1'){
                this.$toast.fail(res.data.msg)
                deleteCookie('token')
                this.$router.push('/login')
            //   window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
            //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
            //         if(res.data.code == 0){
            //             let domain = this.zhuan(res.data.data)
            //             let url = window.location.origin
                
            //             window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
            //         }
            //     })

            }else{
                this.$toast.fail(res.data.msg)

            }

        })


    },
    methods: {
          zhuan(url){
                        let str = url
                        let str1 = str.replace('https','')
                        str1 = str1.replace('http','')
                        let str2 = 'https'+ str1
                        return str2
                    },
        addData(){
            console.log(this.disassembleUserId)
            let arr1 = []
                this.disassembleUserIdList.forEach(i => {
                    this.disassembleUserId.forEach(j=>{
                        if(i.id == j){
                            let obj = {
                                "realName":i.realName,
                                "id":i.id
                            }
                            arr1.push(obj)
                        }
                    })
                });
                let arr = [];
                arr.push(this.$route.query.id)
                //console.log(arr)
            
                let obj = {
                    'disassembleTypeId':this.disassembleTypeId,
                    'disassemblyMethodId':this.disassemblyMethodId,
                    dismant_batch_number:this.dismant_batch_number,
                    'user_json':arr1,
                    'price':this.price,
                    'remark':this.remark,
                    'ids':arr
                }
                let data = encipherMent( JSON.stringify(obj))
                let param = new URLSearchParams();
                param.append("value",data);
            
                 this.$http.post('/index.php/index/Disassemble_Allocation/add',param).then(res=>{
                       let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                    if(result.code == 0) {
                        //console.log(res)
                        // this.show= !this.show
                        this.$toast.success('提交成功')
                        this.$router.push("/WaitingDisassembly")
                        
                    }else{
                        this.$toast.fail(result.msg);
                    }
                })
        },
         previousPage(){
        this.$router.go(-1)
    }
    },
     
}
</script>
<style lang="scss" scoped>
    .header {
        padding: 0 20px;
        background-color: cadetblue;
        height: 1rem;
        line-height: 1rem;
        color: white;
        font-size: 0.4rem;
        display: flex;
        margin-bottom: 20px;
        justify-content:space-between;
        span{
            display: inline-block;
        }
        span:nth-child(1){
            width: 30px;
            height: 30px;
            background-image: url(../../assets/u39.png);
            background-size: 100%;
            margin-top: 8px;
        }
    }
    .mains {
        width: 90%;
        margin:1rem auto;
        font-size: 0.35rem;
          div {
            margin-top: 20px;
            padding-bottom: 5px;
            border-bottom: 1px solid #e0e0e0;
            span {
                width: 2.5rem;
                display: inline-block;
                text-align: center;
                margin-right: 23px;
            }
            input {
                width: 60%;
                height: 0.5rem;
            }
            }
    }
    .btn_box{
        display: flex;
        justify-content:space-between;
        width: 60%;
        margin: 2rem auto;
        div{
            width: 40%;
            height: 0.8rem;
            background-color: cadetblue;
            color: white;
            line-height: 0.8rem;
            text-align: center;
            font-size: 0.4rem;
            border-radius: 5px;;
        }

    }
</style>